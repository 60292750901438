import { BigNumber, ethers } from 'ethers'
import Vue from 'vue'
import Vuex from 'vuex'
import { LiquidityState } from './modules/liquidity'
import { StakingState } from './modules/staking'

Vue.use(Vuex)

export default new Vuex.Store({
    state: () => ({
        currencyBalance: BigNumber.from('0'),
        tokensBalance: BigNumber.from('0'),
        currencyRate: 0,
        lang: 'en'
    }),
    mutations: {
        setCurrencyBalance: function (state, currencyBalance) {
            state.currencyBalance = currencyBalance
        },
        setTokensBalance: function (state, tokensBalance) {
            state.tokensBalance = tokensBalance
        },
        setCurrencyRate: function (state, currencyRate) {
            state.currencyRate = currencyRate
        },
        setLang: function (state, lang) {
            state.lang = lang
        }
    },
    getters: {
        currencyBalanceFormated: function (state) {
            return ethers.utils.formatEther(state.currencyBalance)
        },
        tokensBalanceFormated: function (state) {
            return ethers.utils.formatEther(state.tokensBalance)
        }
    },
    modules: {
        liquidity: LiquidityState,
        staking: StakingState
    }
})
