<template>
    <div class="container screen" >
        <h1>{{ $t("messages.tokenStatistics") }}</h1>
        <div class="screen_block screen_block_column">
            <div>
                <div class="block block_statistics">
                    <h2>{{ $t("messages.titleTokens") }}</h2>
                    <h1><div class="rst">rst</div>{{ tokensBalanceFormated }}</h1>
                    <button class="button-collect">{{ $t("messages.createLiquidity") }}</button>
                </div>
                <ul class="info-list">
                    <li>
                        <div>{{ $t("messages.priceToken") }}:</div>
                        <div>{{ tokenPrice }} USD</div>
                    </li>
                    <li>
                        <div>{{ $t("messages.priceChange") }}:</div>
                        <div>{{ tokenPriceChange }}%</div>
                    </li>
                </ul>
                <div class="block block_statistics_links">
                    <a href="javascript:void(0);" class="button" @click="watchTokenAsset">{{ $t("messages.addTokenToWallet") }}</a>                    
                    <a href="https://pancakeswap.finance/" target="_blank" class="link">{{ $t("messages.goToExchange") }}</a>
                </div>
            </div>
            <div  class="screen_block two_blocks">
                <div class="card-statistics">
                    <div class="card-body">
                        <div class="block block_statistics">
                            <h2>{{ $t("messages.titleLiquidityTokens") }}</h2>
                            <h1><div class="rst">rst <span>lp</span></div>{{ liquidityTokensBalanceFormated }}</h1>
                            <div class="h3">
                                {{ $t("messages.feesAlert") }}
                            </div>
                            <button class="button-collect">{{ $t("messages.Stake") }}</button>
                        </div>
                    </div>
                </div>
                <div class="card-statistics">
                    <div class="card-body">
                        <div class="block block_statistics">
                            <h2>{{ $t("messages.titleTokens") }}</h2>
                            <h1><div class="rst">rst</div>{{ availableRewardFormatted }}</h1>
                            <div class="h3">
                                {{ $t("messages.yourCurrentStakingEarnings") }}
                            </div>
                            <button @click="collectStakingRewards" class="button-collect">{{ $t("messages.Collect") }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
 import { mapState, mapGetters } from 'vuex'
 import { ethers } from 'ethers'

const PERCENT_MULTIPLIER = ethers.BigNumber.from(Math.pow(10, 10).toString())
const ZERO_BN = ethers.BigNumber.from('0')
const ONE_BN = ethers.BigNumber.from('1')

export default {
  name: 'StatisticsPage',
   computed: {
       ...mapState(['currencyRate']),
       ...mapGetters(['tokensBalanceFormated']),
       ...mapState('liquidity', ['currencyReserve', 'tokensReserve']),
       ...mapGetters('staking', ['stakedFormatted', 'availableRewardFormatted']),
       ...mapGetters('liquidity', ['liquidityTokensBalanceFormated', '__userLiquidityPortionPercent']),
        tokensPerCurrency () {
            const tokensReserve = this.tokensReserve.eq(ZERO_BN) ? ONE_BN : this.tokensReserve;
            return this.currencyReserve.mul(PERCENT_MULTIPLIER).div(tokensReserve)
        },
      tokenPrice: function () {
          return Number.parseFloat(ethers.utils.formatEther(this.tokensPerCurrency)) * this.currencyRate / Number.parseFloat(ethers.utils.formatEther(PERCENT_MULTIPLIER))
      },
      tokenPriceChange: function () {
          // todo prod set original price here
          const originalPrice = 1 / 1000 * this.currencyRate
          return this.tokenPrice / originalPrice * 100     
        },
      liquidityToUsd: function () {
          return (Number.parseFloat(ethers.utils.formatEther(this.currencyReserve)) * this.currencyRate + Number.parseFloat(ethers.utils.formatEther(this.tokensReserve)) * this.tokenPrice).toFixed(2)
      },
      liquidityTokensPoolToUsd : function () {
          return (this.liquidityToUsd * this.__userLiquidityPortionPercent.toNumber() / Math.pow(10, 10)).toFixed(2)
      }
   },
   methods: {
      collectStakingRewards: function () {
          this.$contract.collectStakingRewards().then(tx => {
              tx.wait().then(this.onTransactionDone)
          })
       },
      watchTokenAsset: function () {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.$config.TOKEN,
                    symbol: 'RST',
                    decimals: 18
                }
            }
        })
      },
   }
}
</script>
