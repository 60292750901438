<template>
    <div class="content-select">
        <div class="input-container">
            <div class="input-header">
                <div class="input-title">{{ $t("messages.Amount") }}:</div>
                <div class="value">{{ currencyBalanceFormated }} BNB</div>
            </div>
            <div class="input-group"><input v-model="currencyDesiredAmount" @input="currencyAmountChangedHandler" id="currencyDesiredAmountInput" type="number"
                    aria-label="Sizing example input" placeholder="0.0"
                    aria-describedby="inputGroup-sizing-default" min="0" step="0.1"
                    oninput="validity.valid||(value=value.replaceAll('-', ''));"
                    class="form-control"><span id="inputGroup-sizing-default"
                    class="input-group-text">BNB</span></div>
        </div>
        <div class="input-container">
            <div class="input-header">
                <div class="input-title">{{ $t("messages.Amount") }}: </div>
                <div class="value">{{ tokensBalanceFormated }} RST</div>
            </div>
            <div class="input-group"><input v-model="tokensDesiredAmount" @input="tokensAmountChangedHandler" id="tokensDesiredAmountInput" type="number"
                    aria-label="Sizing example input" placeholder="0.0"
                    aria-describedby="inputGroup-sizing-default" min="0" step="0.1"
                    oninput="validity.valid||(value=value.replaceAll('-', ''));"
                    class="form-control"><span id="inputGroup-sizing-default"
                    class="input-group-text">RST</span></div>
        </div>
        <div class="content-select-footer">
            <button @click="addLiquidity">{{ sendTransactionButtonContent }}</button>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers'
import { mapGetters, mapState } from 'vuex'
import { divBigNumbersByConvertingToIntegers } from '@/services/provider.utils'

const MIN_DIFF_PERCENT = 0.01
const DEADLINE_IN_MINUTES = 20
const ZERO_BN = ethers.BigNumber.from('0')
const ONE_BN = ethers.BigNumber.from('1')

export default {
    name: 'AddLiquidityComponent',
    data: function () {
        return {
            currencyDesiredAmount: 0,
            tokensDesiredAmount: 0
        }
    },
    computed: {
        ...mapGetters(['currencyBalanceFormated', 'tokensBalanceFormated']),
        ...mapState('liquidity', ['currencyReserve', 'tokensReserve', 'userToSwapTokensAllowance', 'userToSwapLiquidityTokensAllowance']),
        currencyPerTokens () {
            const currencyReserve = this.currencyReserve.eq(ZERO_BN) ? ONE_BN : this.currencyReserve;
            return divBigNumbersByConvertingToIntegers(this.tokensReserve, currencyReserve)
        },
        tokensPerCurrency () {
            const tokensReserve = this.tokensReserve.eq(ZERO_BN) ? ONE_BN : this.tokensReserve;
            return divBigNumbersByConvertingToIntegers(this.currencyReserve, tokensReserve)
        },
        sendTransactionButtonContent () {
            if (this.userToSwapTokensAllowance.gte(ethers.utils.parseEther(this.tokensDesiredAmount.toString()))) {
                return this.$t("messages.Add")
            } else {
                return this.$t("messages.Approve")
            }
        }
    },
    methods: {
        currencyAmountChangedHandler: function () {
            this.$set(this, 'tokensDesiredAmount', this.currencyDesiredAmount * this.currencyPerTokens)
        },
        tokensAmountChangedHandler: function () {
            this.$set(this, 'currencyDesiredAmount', this.tokensDesiredAmount * this.tokensDesiredAmount)
        },
        addLiquidity: function () {
            if (this.userToSwapTokensAllowance.gte(ethers.utils.parseEther(this.tokensDesiredAmount.toString()))) {
                this.__addLiquidity()
            } else {
                this.__tokensApprove()
            }
        },
        __addLiquidity: async function () {
            const currencyForLiquidityDesired = ethers.utils.parseEther(this.currencyDesiredAmount.toString())
            const currencyForLiquidityMin = ethers.utils.parseEther((this.currencyDesiredAmount - this.currencyDesiredAmount * MIN_DIFF_PERCENT).toString())
            const tokensForLiquidityDesired = ethers.utils.parseEther(this.tokensDesiredAmount.toString())
            const tokensForLiquidityMin = ethers.utils.parseEther((this.tokensDesiredAmount - this.tokensDesiredAmount * MIN_DIFF_PERCENT).toString())
            const deadline = Number.parseInt(Date.now() / 1000) + DEADLINE_IN_MINUTES * 60
            
            const tx = await this.$contract.addLiquidityETH(tokensForLiquidityDesired, currencyForLiquidityDesired, tokensForLiquidityMin, currencyForLiquidityMin, deadline)
            await tx.wait()
            this.$parent.$emit('onTransactionDone')
        },
        __tokensApprove: async function () {
            const tokensForLiquidityDesired = ethers.utils.parseEther(this.tokensDesiredAmount.toString())
            const tx = await this.$contract.tokensApprove(tokensForLiquidityDesired)
            await tx.wait()
            this.$parent.$emit('onTransactionDone')
        }
    }
}
</script>