<template>
    <div class="content-select">
        <div class="input-container">
            <div class="input-header">
                <div class="input-title">{{ $t("messages.Amount") }}: </div>
                <div class="value">{{ liquidityTokensBalanceFormated }} RST-LP</div>
            </div>
            <div class="input-group"><input v-model="amountToStake" id="bnbDesiredAmountInput" type="number"
                    aria-label="Sizing example input" placeholder="0.0"
                    aria-describedby="inputGroup-sizing-default" min="0" step="0.1"
                    oninput="validity.valid||(value=value.replaceAll('-', ''));"
                    class="form-control"><span id="inputGroup-sizing-default"
                    class="input-group-text">RST-LP</span></div>
        </div>
        <div class="content-select-footer">
            <button v-if="isAllowsToStake" @click="stake">{{ $t("messages.Stake") }}</button>
            <button v-else @click="approve">{{ $t("messages.Approve") }}</button>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'AddStakingComponent',
    data: function () {
        return {
            amountToStake: 0
        }
    },
    computed: {
        ...mapState('staking', ['userToContractLiquidityTokensAllowance']),
        ...mapGetters('liquidity', ['liquidityTokensBalanceFormated']),
        amountToStakeParsed () {
            return ethers.utils.parseEther((this.amountToStake || 0).toString())
        },
        // sendTransactionButtonContent () {
        //     if (this.userToContractLiquidityTokensAllowance.gte(this.amountToStakeParsed)) {
        //         return this.$messages.Stake
        //     } else {
        //         return this.$messages.Approve
        //     }
        // },
        isAllowsToStake () {
            return this.userToContractLiquidityTokensAllowance.gte(this.amountToStakeParsed)
        }
    },
    methods: {
        // stake: function () {
        //     if (this.userToContractLiquidityTokensAllowance.gte(this.amountToStakeParsed)) {
        //         this.__stake()
        //     } else {
        //         this.__approve()
        //     }
        // },
        stake: function () {
            const amountToStake = ethers.utils.parseEther(this.amountToStake.toString())
            this.$contract.stake(amountToStake).then(this.__onTransactionDone)
        },
        approve: function () {
            const amountToStake = ethers.utils.parseEther(this.amountToStake.toString())
            this.$contract.approveLiquidityTokensForStaking(amountToStake).then(this.__onTransactionDone)
        },
        __onTransactionDone: function (tx) {
            tx.wait().then(() => this.$parent.$emit('onTransactionDone'))
        }
    }
}
</script>