<template>
    <div>
        <div class="container screen" id="liquidity-screen">
            <h1 id="liquidity">{{ $t("messages.Liquidity") }}</h1>
            <div class="screen_block two_blocks">
                <div>
                    <h3>{{ $t("messages.liquidityDescription") }}</h3>
                        <div class="container_table">
                            <table class="mb-2 table table-striped border-bottom">
                                <tbody>
                                    <tr>
                                        <td>{{ $t("messages.myPoolOfTokens") }}:</td>
                                        <td class="bold">{{ liquidityTokensBalanceFormated }} RST-LP <span class="nearly">≈ ${{ liquidityTokensPoolToUsd }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("messages.Liquidity") }}:</td>
                                        <td>${{ liquidityToUsd }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("messages.pairAddress") }}:</td>
                                        <td>
                                            <a :href="'https://testnet.bscscan.com/address/' + pairAddress" target="_blank">{{ pairAddressShorter }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t("messages.tokenAddress") }}:</td>
                                        <td>
                                            <a :href="'https://testnet.bscscan.com/address/' + tokenAddress" target="_blank">{{ tokenAddressShorter }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <a href="https://pancakeswap.finance/" target="_blank" class="link">{{ $t("messages.getToken") }}</a>
                        <a href="javascript:void(0);" class="link" @click="watchTokenAsset">{{ $t("messages.addTokenToWallet") }}</a>
                        <a href="javascript:void(0);" class="link" @click="watchLpTokenAsset">{{ $t("messages.addLpTokenToWallet") }}</a>
                </div>
                <liquidity-panel-component @onTransactionDone="onTransactionDone" />
            </div>
        </div>
        <div class="container screen" id="staking-screen">
            <h1 id="staking">{{ $t("messages.Staking") }}</h1>
            <div class="screen_block two_blocks">
            <staking-panel-component @onTransactionDone="onTransactionDone" />
                <div>
                    <ul class="info-list">
                        <li>
                            <div>{{ $t("messages.stakedTokens") }}:</div>
                            <div>{{ stakedFormatted }} RST-LP</div>
                        </li>
                        <li>
                            <div>{{ $t("messages.Earned") }}:</div>
                            <div>{{ availableRewardFormatted }}</div>
                        </li>
                        <li>
                            <div>{{ $t("messages.stakingProfitability") }}:</div>
                            <div>~3,5%</div>
                        </li>
                    </ul>
                    <h3>{{ $t("messages.feeAlert") }}</h3>
                    <div class="block">
                        <h2>{{ $t("messages.tokensEarned") }}</h2>
                        <h1>{{ availableRewardFormatted }}</h1>
                        <h3>{{ $t("messages.stakingDescription") }}</h3>
                            <button @click="collectStakingRewards" class="button-collect">{{ $t("messages.Collect") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import { mapState, mapGetters } from 'vuex'
 import { ethers } from 'ethers'
import LiquidityPanelComponent from '@/components/liquidity/LiquidityPanelComponent.vue'
import StakingPanelComponent from '@/components/staking/StakingPanelComponent.vue'

const PERCENT_MULTIPLIER = ethers.BigNumber.from(Math.pow(10, 10).toString())
const ZERO_BN = ethers.BigNumber.from('0')
const ONE_BN = ethers.BigNumber.from('1')

function getShorterAddress (address, tailsLength = 4) {
    return address.substring(0, tailsLength) + '...' + address.substring(address.length - tailsLength, address.length)
}

export default {
    name: 'LiquidityPage',
    components: {
        LiquidityPanelComponent,
        StakingPanelComponent,
    },
   computed: {
       ...mapState(['currencyRate']),
       ...mapState('liquidity', ['currencyReserve', 'tokensReserve']),
       ...mapGetters('staking', ['stakedFormatted', 'availableRewardFormatted']),
       ...mapGetters('liquidity', ['liquidityTokensBalanceFormated', '__userLiquidityPortionPercent']),
      pairAddress: function () {
          return this.$config.LP_TOKEN
      },
      pairAddressShorter: function () {
          return getShorterAddress(this.$config.LP_TOKEN)
      },
      tokenAddress: function () {
          return this.$config.TOKEN
      },
      tokenAddressShorter: function () {
          return getShorterAddress(this.$config.TOKEN)
      },
      currencyPerTokens () {
            const currencyReserve = this.currencyReserve.eq(ZERO_BN) ? ONE_BN : this.currencyReserve;
            return this.tokensReserve.mul(PERCENT_MULTIPLIER).div(currencyReserve)
        },
        tokensPerCurrency () {
            const tokensReserve = this.tokensReserve.eq(ZERO_BN) ? ONE_BN : this.tokensReserve;
            return this.currencyReserve.mul(PERCENT_MULTIPLIER).div(tokensReserve)
        },
      tokenPrice: function () {
          return Number.parseFloat(ethers.utils.formatEther(this.tokensPerCurrency)) * this.currencyRate / Number.parseFloat(ethers.utils.formatEther(PERCENT_MULTIPLIER))
      },
    //   tokenPriceChange: function () {
    //       // todo prod set original price here
    //       const originalPrice = 1 / 1000 * this.currencyRate
    //       return this.tokenPrice / originalPrice * 100
          
    //   },
      liquidityToUsd: function () {
          return (Number.parseFloat(ethers.utils.formatEther(this.currencyReserve)) * this.currencyRate + Number.parseFloat(ethers.utils.formatEther(this.tokensReserve)) * this.tokenPrice).toFixed(2)
      },
      liquidityTokensPoolToUsd : function () {
          return (this.liquidityToUsd * this.__userLiquidityPortionPercent.toNumber() / Math.pow(10, 10)).toFixed(2)
      }
   },
   methods: {
      onAccountChanged: function () {
        this.$contract.getCurrencyBalanceAndSaveToStore()
        this.$contract.getTokensBalanceAndSaveToStore()
        this.$contract.getUserLiquidityTokensBalanceAndSaveToStore()
        this.$contract.getTokensAllowanceAndSaveToStore()
        this.$contract.getLiquidityTokensAllowanceAndSaveToStore()
        this.$contract.getLiquidityTokensAllowanceForStakingAndSaveToStore()
        this.$contract.getStakingStatisticsAndSaveToStore()
      },
      onTransactionDone: function () {
        this.$contract.getLiquidityTokensAmountAndSaveToStore()
        this.$contract.saveLiquidityPairReservesToStore()

        if (this.$contract.isAccountConnected()) {
            this.$contract.getCurrencyBalanceAndSaveToStore()
            this.$contract.getTokensBalanceAndSaveToStore()
            this.$contract.getUserLiquidityTokensBalanceAndSaveToStore()
            this.$contract.getTokensAllowanceAndSaveToStore()
            this.$contract.getLiquidityTokensAllowanceAndSaveToStore()
            this.$contract.getLiquidityTokensAllowanceForStakingAndSaveToStore()
            this.$contract.getStakingStatisticsAndSaveToStore()
        }
      },
      collectStakingRewards: function () {
          this.$contract.collectStakingRewards().then(tx => {
              tx.wait().then(this.onTransactionDone)
          })
      },
      watchTokenAsset: function () {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.$config.TOKEN,
                    symbol: 'RST',
                    decimals: 18
                }
            }
        })
      },
      watchLpTokenAsset: function () {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.$config.LP_TOKEN,
                    symbol: 'RST-LP',
                    decimals: 18
                }
            }
        })
      },
//         getLatestCurrencyPrice () {
//         const requestLink = 'https://api.coincap.io/v2/assets/binance-coin'
//         const delay = 5 * 60 // 5 minutes
//         const priceKey = 'currencyPrice'
//         const delayKey = 'currncyPriceDelay'

//         return new Promise (resolve => {
//             const currencyPrice = Number.parseFloat(localStorage.getItem(priceKey))
//             const currencyPriceTimestamp = Number.parseInt(localStorage.getItem(delayKey))

//             const requestForPrice = () => axios.get(requestLink).then(responce => {
//                 const price = Number.parseFloat(responce.data.data.priceUsd)
//                 localStorage.setItem(priceKey, price)
//                 localStorage.setItem(delayKey, Date.now())
//                 resolve(price)
//             })

//             if (currencyPrice) {
//                 if (currencyPriceTimestamp && currencyPriceTimestamp + delay >= Date.now()) {
//                     requestForPrice()
//                 } else {
//                     resolve(currencyPrice)
//                 }
//             } else {
//                 requestForPrice()
//             }
//         })
//     }
   },
}
</script>
