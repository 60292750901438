<template>
    <div>
        <main class="homePageContent">
            <header>
            <div class="container">
                    <button class="button-mob-menu"  @click="mobileMenu = !mobileMenu" :class="{active: mobileMenu}" >
                        <span>{{ $t("messages.Menu") }}</span>
                        <span>&times;</span>
                    </button>
                    <router-link :to="{ name : 'statistics' }" class="button-header button-header-statistics">{{ $t("messages.tokenStatistics") }}</router-link>
                    <ul>
                        <li><router-link :to="{ name : 'staking', hash: '#liquidity' }">{{ $t("messages.Liquidity") }}</router-link></li>
                        <li><router-link :to="{ name : 'staking', hash : '#staking' }">{{ $t("messages.Staking") }}</router-link></li>
                        <button class="button-lang">
                            <div class="lang-active">{{ lang }}</div>
                            <ul class="lang-list">
                                <li @click="setLang('ru')">ru</li>
                                <li @click="setLang('en')">en</li>
                            </ul>
                        </button>
                        <connect-button-component @onAccountConnected="onAccountChanged" ref="connectButton"  class="button-header button-header-connect"/>
                    </ul>
                </div>
            </header>
            <router-view />
        </main>
        <footer>{{ $t("messages.footerDescription") }}</footer>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
import { ethers } from 'ethers'
import ContractService from '@/services/ContractService'
import ConnectButtonComponent from './components/ConnectButtonComponent.vue'
import { detectAnyProvider } from '@/services/provider.utils.js'
import './assets/css/main.css'

const PERCENT_MULTIPLIER = ethers.BigNumber.from(Math.pow(10, 10).toString())
const ZERO_BN = ethers.BigNumber.from('0')
const ONE_BN = ethers.BigNumber.from('1')

const LANG_KEY = 'lang'

function getShorterAddress (address, tailsLength = 4) {
    return address.substring(0, tailsLength) + '...' + address.substring(address.length - tailsLength, address.length)
}

export default {
  name: 'App',
  components: {
    ConnectButtonComponent
  },
  data: function () {
      return {
          isStatisticsPageOpen: true,
          mobileMenu: false,
      }
  },
  computed: {
      ...mapState(['currencyRate', 'lang']),
      ...mapGetters(['tokensBalanceFormated']),
      ...mapState('liquidity', ['currencyReserve', 'tokensReserve']),
      ...mapGetters('staking', ['stakedFormatted', 'availableRewardFormatted']),
      ...mapGetters('liquidity', ['liquidityTokensBalanceFormated', '__userLiquidityPortionPercent']),
      pairAddress: function () {
          return this.$config.LP_TOKEN
      },
      pairAddressShorter: function () {
          return getShorterAddress(this.$config.LP_TOKEN)
      },
      tokenAddress: function () {
          return this.$config.TOKEN
      },
      tokenAddressShorter: function () {
          return getShorterAddress(this.$config.TOKEN)
      },
      currencyPerTokens () {
            const currencyReserve = this.currencyReserve.eq(ZERO_BN) ? ONE_BN : this.currencyReserve;
            return this.tokensReserve.mul(PERCENT_MULTIPLIER).div(currencyReserve)
        },
        tokensPerCurrency () {
            const tokensReserve = this.tokensReserve.eq(ZERO_BN) ? ONE_BN : this.tokensReserve;
            return this.currencyReserve.mul(PERCENT_MULTIPLIER).div(tokensReserve)
        },
      tokenPrice: function () {
          return Number.parseFloat(ethers.utils.formatEther(this.tokensPerCurrency)) * this.currencyRate / Number.parseFloat(ethers.utils.formatEther(PERCENT_MULTIPLIER))
      },
      tokenPriceChange: function () {
          // todo prod set original price here
          const originalPrice = 1 / 1000 * this.currencyRate
          return this.tokenPrice / originalPrice * 100
          
      },
      liquidityToUsd: function () {
          return (Number.parseFloat(ethers.utils.formatEther(this.currencyReserve)) * this.currencyRate + Number.parseFloat(ethers.utils.formatEther(this.tokensReserve)) * this.tokenPrice).toFixed(2)
      },
      liquidityTokensPoolToUsd : function () {
          return (this.liquidityToUsd * this.__userLiquidityPortionPercent.toNumber() / Math.pow(10, 10)).toFixed(2)
      }
  },
  methods: {
      onAccountChanged: function () {
        this.$contract.getCurrencyBalanceAndSaveToStore()
        this.$contract.getTokensBalanceAndSaveToStore()
        this.$contract.getUserLiquidityTokensBalanceAndSaveToStore()
        this.$contract.getTokensAllowanceAndSaveToStore()
        this.$contract.getLiquidityTokensAllowanceAndSaveToStore()
        this.$contract.getLiquidityTokensAllowanceForStakingAndSaveToStore()
        this.$contract.getStakingStatisticsAndSaveToStore()
      },
      onTransactionDone: function () {
        this.$contract.getLiquidityTokensAmountAndSaveToStore()
        this.$contract.saveLiquidityPairReservesToStore()

        if (this.$contract.isAccountConnected()) {
            this.$contract.getCurrencyBalanceAndSaveToStore()
            this.$contract.getTokensBalanceAndSaveToStore()
            this.$contract.getUserLiquidityTokensBalanceAndSaveToStore()
            this.$contract.getTokensAllowanceAndSaveToStore()
            this.$contract.getLiquidityTokensAllowanceAndSaveToStore()
            this.$contract.getLiquidityTokensAllowanceForStakingAndSaveToStore()
            this.$contract.getStakingStatisticsAndSaveToStore()
        }
      },
      collectStakingRewards: function () {
          this.$contract.collectStakingRewards().then(tx => {
              tx.wait().then(this.onTransactionDone)
          })
      },
      watchTokenAsset: function () {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.$config.TOKEN,
                    symbol: 'RST',
                    decimals: 18
                }
            }
        })
      },
      watchLpTokenAsset: function () {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.$config.LP_TOKEN,
                    symbol: 'RST-LP',
                    decimals: 18
                }
            }
        })
      },
        getLatestCurrencyPrice () {
        const requestLink = 'https://api.coincap.io/v2/assets/binance-coin'
        const delay = 5 * 60 // 5 minutes
        const priceKey = 'currencyPrice'
        const delayKey = 'currncyPriceDelay'

        return new Promise (resolve => {
            const currencyPrice = Number.parseFloat(localStorage.getItem(priceKey))
            const currencyPriceTimestamp = Number.parseInt(localStorage.getItem(delayKey))

            const requestForPrice = () => axios.get(requestLink).then(responce => {
                const price = Number.parseFloat(responce.data.data.priceUsd)
                localStorage.setItem(priceKey, price)
                localStorage.setItem(delayKey, Date.now())
                resolve(price)
            })

            if (currencyPrice) {
                if (currencyPriceTimestamp && currencyPriceTimestamp + delay >= Date.now()) {
                    requestForPrice()
                } else {
                    resolve(currencyPrice)
                }
            } else {
                requestForPrice()
            }
        })
    },
    setLang (lang) {
        this.$store.commit('setLang', lang)
        this.$i18n.locale = lang
        localStorage.setItem(LANG_KEY, lang)
    },
  },
  mounted () {
      detectAnyProvider().then(providerInstance => {
          Vue.prototype.$contract = new ContractService(this, providerInstance)
          this.$contract.getLiquidityTokensAmountAndSaveToStore()
          this.$contract.saveLiquidityPairReservesToStore()
          this.$refs.connectButton.checkCachedWallet()
      })

      this.getLatestCurrencyPrice().then(price => {
            this.$store.commit('setCurrencyRate', price)
        })

        const cachedLang = localStorage.getItem(LANG_KEY)
        if (cachedLang) {
            this.setLang(cachedLang)
        }
  }
}
</script>
