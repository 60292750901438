<template>
    <div class="content-select">
        <ul class="info-list">
            <li>
                <div>RST :</div>
                <div>{{ formatedCurrentUserTokensBalance }}</div>
            </li>
            <li>
                <div>BNB :</div>
                <div>{{ formatedCurrentUserCurrencyBalance }}</div>
            </li>
        </ul>
        <div class="input-container">
            <div class="input-header">
                <div class="input-title">{{ $t("messages.Amount") }}: </div>
                <div class="value">{{ formatedCurrentUserLiquidityTokensBalance }} RST-LP</div>
            </div>
            <div class="input-group"><input v-model="flipTokensToRemoveLiquidity" id="bnbDesiredAmountInput" type="number"
                    aria-label="Sizing example input" placeholder="0.0"
                    aria-describedby="inputGroup-sizing-default" min="0" step="0.1"
                    oninput="validity.valid||(value=value.replaceAll('-', ''));"
                    class="form-control">
            </div>
        </div>
        <div class="container-select-amount">
            <button class="btn" @click="setAmountToRemoveByPercent25">25%</button>
            <button class="btn" @click="setAmountToRemoveByPercent50">50%</button>
            <button class="btn" @click="setAmountToRemoveByPercent75">75%</button>
            <button class="btn" @click="setAmountToRemoveByPercent100">100%</button>
        </div>
        <div class="content-select-footer">
            <button @click="removeLiquidity">{{ sendTransactionButtonContent }}</button>
            <a href="" target="_blank">{{ $t("messages.goToExchange") }}</a>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers'
import { mapState, mapGetters } from 'vuex'

const ZERO_BN = ethers.BigNumber.from('0')
const PERCENTS_DIVIDER = ethers.BigNumber.from(Math.pow(10, 10).toString())
const DEADLINE_IN_MINUTES = 20

export default {
    name: 'RemoveLiquidityComponent',
    data: function () {
        return {
            flipTokensToRemoveLiquidity: 0
        }
    },
    computed: {
        ...mapState('liquidity', ['currentUserLiquidityTokensBalance', 'userToSwapLiquidityTokensAllowance']),
        ...mapGetters('liquidity', ['currentUserCurrencyBalance', 'currentUserTokensBalance']),
        formatedCurrentUserCurrencyBalance () {
            return ethers.utils.formatEther(this.currentUserCurrencyBalance)
        },
        formatedCurrentUserTokensBalance () {
            return ethers.utils.formatEther(this.currentUserTokensBalance)
        },
        formatedCurrentUserLiquidityTokensBalance () {
            return ethers.utils.formatEther(this.currentUserLiquidityTokensBalance)
        },
        sendTransactionButtonContent () {
            if (this.userToSwapLiquidityTokensAllowance.gte(ethers.utils.parseEther(this.flipTokensToRemoveLiquidity.toString()))) {
                return 'Remove'
            } else {
                return 'Approve'
            }
        }
    },
    methods: {
        removeLiquidity: function () {
            if (this.userToSwapLiquidityTokensAllowance.gte(ethers.utils.parseEther(this.flipTokensToRemoveLiquidity.toString()))) {
                this.__removeLiquidity()
            } else {
                this.__lpTokensApprove()
            }
        },
        __removeLiquidity: async function () {
            let liquidityPercent
            try {
                liquidityPercent = this.currentUserLiquidityTokensBalance.eq(ZERO_BN) ? ZERO_BN : ethers.utils.parseEther(this.flipTokensToRemoveLiquidity).mul(PERCENTS_DIVIDER).div(this.currentUserLiquidityTokensBalance)
            } catch (e) {
                console.error(e)
                liquidityPercent = ethers.BigNumber.from('0')
            }

            const liquidity = ethers.utils.parseEther(this.flipTokensToRemoveLiquidity.toString())
            const amountTokensMin = this.currentUserTokensBalance.mul(liquidityPercent).div(PERCENTS_DIVIDER)
            const amountCurrencyMin = this.currentUserCurrencyBalance.mul(liquidityPercent).div(PERCENTS_DIVIDER)
            const deadline = Number.parseInt(Date.now() / 1000) + DEADLINE_IN_MINUTES * 60

            console.log(ethers.utils.formatEther(amountTokensMin), ethers.utils.formatEther(amountCurrencyMin))

            const tx = await this.$contract.removeLiquidityETH(liquidity, amountTokensMin, amountCurrencyMin, deadline)
            await tx.wait()
            this.$parent.$emit('onTransactionDone')
        },
        __lpTokensApprove: async function () {
            const lpTokensToRemoveLiquidity = ethers.utils.parseEther(this.flipTokensToRemoveLiquidity.toString())
            const tx = await this.$contract.lpTokensApprove(lpTokensToRemoveLiquidity)
            await tx.wait()
            this.$parent.$emit('onTransactionDone')
        },
        __setAmountToRemoveByPercent: function (percent) {
            const amountToRemove = Number.parseFloat(this.formatedCurrentUserLiquidityTokensBalance) * percent
            this.$set(this, 'flipTokensToRemoveLiquidity', amountToRemove)
        },
        setAmountToRemoveByPercent25: function () {
            this.__setAmountToRemoveByPercent(0.25)
        },
        setAmountToRemoveByPercent50: function () {
            this.__setAmountToRemoveByPercent(0.5)
        },
        setAmountToRemoveByPercent75: function () {
            this.__setAmountToRemoveByPercent(0.75)
        },
        setAmountToRemoveByPercent100: function () {
            this.__setAmountToRemoveByPercent(1)
        }
    }
}
</script>