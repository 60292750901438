import { BigNumber, ethers } from 'ethers'
// import { divBigNumbersByConvertingToIntegers } from '@/services/provider.utils'

const PERCENT_MULTIPLIER = ethers.BigNumber.from(Math.pow(10, 10).toString())
const ZERO_BN = ethers.BigNumber.from('0')

// function floorToFixed(value, digitsAfterDot) {
//     const multiplier = Math.pow(10, digitsAfterDot)
//     return Math.floor(digitsAfterDot * multiplier) / multiplier
// }

export const LiquidityState = {
    namespaced: true,
    state: () => ({
        currencyReserve: BigNumber.from('0'),
        tokensReserve: BigNumber.from('0'),
        currentUserLiquidityTokensBalance: BigNumber.from('0'),
        liquidityTokensAmount: BigNumber.from('0'),
        userToSwapTokensAllowance: BigNumber.from('0'),
        userToSwapLiquidityTokensAllowance: BigNumber.from('0')
    }),
    getters: {
        __userLiquidityPortionPercent (state) {
            return state.liquidityTokensAmount.eq(ZERO_BN) ? ZERO_BN : state.currentUserLiquidityTokensBalance.mul(PERCENT_MULTIPLIER).div(state.liquidityTokensAmount)
            // const amount = Number.parseFloat(ethers.utils.formatEther(state.liquidityTokensAmount))
            // const balance = Number.parseFloat(ethers.utils.formatEther(state.currentUserLiquidityTokensBalance))
            // return (amount == 0 ? 0 : balance / amount) * PERCENT_MULTIPLIER
        },
        currentUserCurrencyBalance: function (state, getters) {
            return state.currencyReserve.mul(getters.__userLiquidityPortionPercent).div(PERCENT_MULTIPLIER)
        },
        currentUserTokensBalance: function (state, getters) {
            return state.tokensReserve.mul(getters.__userLiquidityPortionPercent).div(PERCENT_MULTIPLIER)
        },
        liquidityTokensBalanceFormated: function (state) {
            return ethers.utils.formatEther(state.currentUserLiquidityTokensBalance)
        }
    },
    mutations: {
        setReserves: function (state, reserves) {
            state.currencyReserve = reserves[1]
            state.tokensReserve = reserves[0]
        },
        setCurrentUserLiquidityTokensBalance (state, currentUserLiquidityTokensBalance) {
            state.currentUserLiquidityTokensBalance = currentUserLiquidityTokensBalance
        },
        setLiquidityTokensAmount: function (state, liquidityTokensAmount) {
            state.liquidityTokensAmount = liquidityTokensAmount
        },
        setTokensAllowance: function (state, allowance) {
            state.userToSwapTokensAllowance = allowance
        },
        setLiquidityTokensAllowance: function (state, allowance) {
            state.userToSwapLiquidityTokensAllowance = allowance
        }
    }
}
