import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import store from './store'
import router from './router'
import production from '@/config/production.json'
import development from '@/config/development.json'
import { messages } from '@/translations'

if (process.env.NODE_ENV === 'production') {
  Vue.prototype.$config = Object.freeze(production)
} else {
  Vue.prototype.$config = Object.freeze(development)
}

Vue.config.productionTip = false
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  messages
})

new Vue({
  store,
  render: h => h(App),
  i18n,
  router
}).$mount('#app')
